#stake-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .balance {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }

  .balance-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .apy-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .data-row {
    margin: 13px 0px;
  }

  .stake-table-panel,
  .stake-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .stake-tab-panel,
  .mobile .stake-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .textTertiary {
    color: #7F7F7F;
  }

  .ohm-card {
    padding: 32px;
    border-radius: 10px;
  }
  .ohm-card .header {
    min-height: 3em;
  }
  .apy-card {
    border-width: 2px;
    border-style: solid;
    width: auto;
    align-items: top;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #0F182A;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .ohm-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
  .inputMaxButton {
    background-color: rgba(9,147,236,0.3);
    border-color: rgb(9, 147, 236);
    border-radius: 16px;
    border-width: 1px;
    border-style: solid;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgb(9, 147, 236);
    &:hover {
      background-color: rgba(9,147,236,0.5);
    }
  }
}

.migrate-sohm-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-sohm-button.complete {
  color: #35ee66 !important;
}

.maxWebAppWidth {
  max-width: 912px;
}

.stake-top-metrics {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  h5 {
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
  }
  .stake-apy,
  .stake-tvl {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mobile .stake-top-metrics {
  text-align: left;
}

.mobile .stake-apy {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ohm-logo-bg {
  height: 22px;
  width: 22px;
  border-radius: 20px;
  display: flex;
  background-color: white;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #333;
}

.ohm-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.stake-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.stake-action-row {
}

.mobile .stake-action-row {
  justify-content: center;
}

.stake-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .stake-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

.formInput {
  background: #202230;
  height: 56px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 10px !important;
}

.ohm-input {
  max-width: 542px;
  width: 100%;
  margin: 5px !important;
}

.mobile .stake-button {
  width: 100% !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
  margin: 10px 0 0 0;
}

.staking-area {
  margin: 13px 10px 13px 10px;
}

.stake-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.stake-user-data {
  justify-content: center;
  margin-top: 20px;
}

.tablet .stake-pool,
.mobile .stake-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .ohm-pairs p {
      margin-bottom: 0 !important;
    }
  }
  .ohm-logo-bg {
    height: 38px !important;
    width: 38px !important;
    padding: 8px !important;
    margin-left: 0;
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .stake-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.stake-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      //max-width: 300px !important;
      height: 56px;
      min-width: 240px !important;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      padding: 14px 24px 14px 24px;
    }
  }
}

.approve-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
}

.stake-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
  background: linear-gradient(to right,#fe5a75,#fec464);
}

.unstake-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
  background: linear-gradient(to right,#28F4AE,#0993EC);
}

.stake-button:hover,
.unstake-button:hover {
  opacity: 0.8;
}

.NotAvailable .MuiLink-root {
  color: #0993EC;
}

.NotAvailable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.4);
}

.NotAvailableBox {
  max-width: 520px;
  margin: 100px auto 0;
}

.NotAvailableBox h1 {
  font-size: 36px;
  line-height: 44px;
}