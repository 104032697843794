.dapp-topbar {
  width: 100%;
  align-items: center;
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding-left: 11px !important;
      padding-right: 13px !important;
      padding-top: 11px !important;
      padding-bottom: 13px !important;
      text-align: center;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}

.MuiButtonBase-root.menuButton {
  font-size: 16px;
  line-height: 24px;
  height: 42px;
}

.MuiTooltip-popper.menuTooltip {
  color: yellow;
}