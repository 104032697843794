#bond-view {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 69;
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.424) !important;
    overflow-y: scroll;
    padding-top: 320px;
    padding-bottom: 80px;
    .ohm-card {
      opacity: 1 !important;
      -moz-opacity: 1 !important;
      height: auto;
    }
  }
  .ohm-card {
    border-radius: 10px;
  }
  .ohm-modal {
    max-width: 688px;
    min-height: 605px;
  }

  .maxWebAppWidth {
    max-width: 912px;
  }

  .inputMaxButton {
    background-color: rgba(40,244,174,0.3);
    border-color: #28F4AE;
    border-radius: 16px;
    border-width: 1px;
    border-style: solid;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color:#28F4AE;
    &:hover {
      background-color: rgba(40,244,174,0.5);
    }
  }

  .bondHeader {
    background-color: #172029;
    border-bottom: 8px solid #28F4AE;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .FairbondHeader {
    background-color: #201824;
    border-bottom: 8px solid #E04DBE;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .bond-header {
    .bond-header-logo {
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: row;
      .ohm-logo-bg {
        height: 40px !important;
        width: 40px !important;
        padding: 8px !important;
        margin-left: 0;
      }
      .bond-header-name {
      }
    }
  }

  .bond-price-data-row {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 23px;
    h4 {
      margin-bottom: 0;
    }
    h3.price {
      font-weight: 600 !important;
    }
  }

  .ohm-input {
    min-width: 50%;
    max-width: 408px;
    margin: 5px;
  }
}

.transaction-button {
  min-width: 185px !important;
  max-width: 408px;
  width: 100%;
  margin: 5px !important;
}

.mobile #bond-view {
  .ohm-card {
    .card-content {
      justify-content: space-evenly !important;
      .bond-toggle-row {
        justify-content: space-evenly !important;
      }
      .input-row {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;
        .input-group.ohm-input-group {
          margin: 10px !important;
          width: 75%;
          height: 50px;
        }
        div.stake-button {
          width: 75%;
          margin-left: 0;
          margin-top: 15px;
          height: 50px;
          font-size: 1.3em !important;
        }
      }
    }
  }
}

.pendingRewards {
  border: 4px solid #202230;
  border-radius: 10px;
}

.pendingRewardsNumber {
  color: #28F4AE;
}

.approve-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
}

.bond-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
  background-image: linear-gradient(to right,#0993EC,#28F4AE);
}

.claim-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
  background: linear-gradient(to right,#28F4AE,#1F8B6C);
}

.claim-stake-button {
  height: 56px;
  min-width: 240px !important;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px 14px 24px;
  background: linear-gradient(to right,#28F4AE,#0993EC);
}

.bond-button:hover,
.claim-button:hover,
.claim-stake-button:hover {
  opacity: 0.8;
}

