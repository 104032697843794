.sidebar {
  min-width: 280px;
  z-index: 2;
  border-radius: 0 !important;
}

.sidebar .MuiLink-root.active {
  text-decoration: none;
}

.MuiDrawer-paper .MuiPaper-root {
  border-radius: 0 !important;
}

.dapp-sidebar {
  height: 100vh;
  border-radius: 0 !important;
}

.walletBox {
  font-size: 16px;
  line-height: 18px;
  padding: 10px 10px;
  background: #161522;
  border: 2px solid #202330;
  font-weight: 500;
  border-radius: 10px;
  color: #7F7F7F;
}

.branding-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-decoration: none;
  margin: 30px 0 10px 0;
  .branding-header-icon {
    width: 50px;
    filter: invert(1);
    display: block;
    margin: 0 auto;
  }
  .wallet-link {
  }
}

.dapp-menu-top {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 32px;
  min-height: 50%;
  .dapp-nav {
    display: flex;
    flex-flow: column;
  }
}
.dapp-menu-links {
  min-height: 40vh;
  display: flex;
  align-items: center;
}
.dapp-nav a {
  margin-top: 0.7rem;
  margin-left: 32px;
  align-items: center;
  margin-bottom: 0.5rem;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: -4px;
  }
}

.dapp-menu-external-links {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  height: 30%;
  min-height: 19%;
  margin-left: 32px;
  a {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
  }
  a svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: -4px;
  }
}

.dapp-menu-data {
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  justify-content: left;
  &.discounts {
    margin-top: 0.5em;
  }
  &.bottom {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    max-height: 38%;
    min-height: 15%;
    height: auto;
  }
}

.bond-discounts {
  text-align: left;
  padding-left: 32px;
  margin-top: 8px;
  > p {
    margin-left: 32px;
  }
  .bond {
    display: block;
    padding: unset;
    margin-bottom: 0 0 1px 0;
    text-decoration: none !important;
    .bond-pair-roi {
      float: right;
      margin-left: 32px;
    }
  }
}

.dapp-menu-social {
  width: 100%;
  display: block;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.social-row {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  padding: 1.3rem;
  a {
    &:hover {
      transform: scale(1.1);
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
}

.mobile .show .dapp-sidebar {
  .dapp-menu-links {
    flex-flow: row !important;
    justify-content: center !important;
  }
  .dapp-menu-external-links {
    padding-top: 3em;
    line-height: 200% !important;
    justify-content: center;
  }
  .dapp-nav,
  .dapp-menu-external-links {
    flex-flow: column !important;
    justify-content: flex-start;
    margin: auto;
    a {
      padding: 0;
      svg {
        align-self: center;
      }
    }
  }
  .dapp-menu-external-links a {
    justify-content: left;
  }
  .dapp-nav {
    .dapp-menu-data.discounts {
      margin-top: 2.5em;
      .bond-discounts {
        display: none;
      }
    }
  }
}
