* {
  outline: none;
}

html,
body,
#root {
  font-family: "Roobert";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Roobert";
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.card-header {
  width: 100%;
  padding: 20px;
  min-height: 2rem;
  h5 {
    font-weight: 600 !important;
  }
}
.ohm-card {
  //.card-header {
  //  width: 100%;
  //  padding: 20px;
  //  min-height: 2rem;
  //  h5 {
  //    font-weight: 600 !important;
  //  }
  //}
  .card-content {
    width: 100%;
    position: relative;
    align-items: left;
    justify-content: center;
    flex-flow: column;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    height: 100%;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 0px;
  }
}

// For formatting the pairs
// JBTD: -- move into its own component
.ohm-pairs {
  display: flex;
  align-items: center;
}

.ohm-pair {
  width: 40px;
  height: 40px;
  margin-left: -8px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  justify-content: center;
  img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

a:hover svg {
  color: inherit;
}

.colored-circle-status {
  display: inline-block;
  margin-left: 8px;
  align-self: center;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
