#hades {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: #55555533;
  .ohm-popover {
    max-width: 512px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 4px solid #161522;
    h3 {
      align-self: center;
      justify-self: center;
      width: 80%;
      text-align: center;
    }
    label {
      margin: 32px 0 10px;
    }
  }
  .mobile .ohm-popover {
    max-width: 400px;
  }
}
