#choose-bond-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  .ohm-card .bond-hero h4 {
    font-weight: 600 !important;
  }
  .ohm-logo-bg {
    margin-left: 0;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    width: 40px;
  }
  .bondHeader {
    background-color: #172029;
    border-bottom: 8px solid #28F4AE;
  }

  .FairbondHeader {
    background-color: #201824;
    border-bottom: 8px solid #E04DBE;
  }

  .bond-data-card {
    padding: 25px;
    margin: auto !important;
    .ohm-pairs {
      display: flex;
      margin-right: 15px;
    }
    
    .bond-pair {
      display: flex !important;
      align-items: center;
      justify-content: left;
      margin-left: 10px;
      margin-bottom: 15px;
      .ohm-pair {
        height: 40px;
        width: 40px;
      }
      .bond-name {
        svg {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 15px;
        }
      }
    }
    .data-row {
      margin-bottom: 13px;
    }
    .bond-link {
      margin-top: 10px;
      text-align: center;
      a {
        width: 96%;
      }
    }
  }

  .MuiTableCell-root.MuiTableCell-body {
    padding: 16px;
  }

  .MuiTableBody-root .MuiTableRow-root {
    height: 92px;
  }

  .MuiTableRow-root {
    cursor: pointer;
  }

  .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .MuiTableCell-root {
    p {
      img {
        margin-right: 3px;
      }
    }
  }

  .MuiTableRow-root:hover {
    background: #172029;
  }

  .textTertiary {
    color: #7F7F7F;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-alignLeft {
    justify-content: space-between;
    div.ohm-pairs {
      width: 33%;
      vertical-align: middle !important;
      align-items: center !important;
      justify-content: center !important;
      margin-bottom: 0 !important;
      .ohm-pairs div.bond-name p,
      .ohm-pairs svg {
        align-self: center !important;
        line-height: 100% !important;
        font-weight: bold !important;
        margin-bottom: 0em !important;
        padding: 0 !important;
        font-family: inherit;
        vertical-align: text-top !important;
      }
      .ohm-logo-bg {
        margin-left: 0;
        border-radius: 20px;
        height: 40px;
        width: 40px;
      }
    }
    div.bond-name {
      width: 60%;
      margin-left: 8px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
      p {
        margin-bottom: unset !important;
        line-height: 140% !important;
      }
    }
  }
}
