#ohm-menu-button {
  padding: 12px;
  svg {
    margin-right: 3px;
    color: inherit !important;
  }
}

.MuiButtonBase-root.menuButton {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  height: 42px;
}

.MuiPaper-root.ASGMenu {
  border-radius: 10px;
  background: #202231;
}

#ohm-popper {
  background-color: #00000011;
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  margin-top: 10px;
  border-radius: 10px;
  .MuiPaper-root.ohm-menu {
    display: flex;
    flex-direction: column;
    width: 200px;
    a {
      margin-bottom: 18px !important;
      padding-left: 20px !important;
      svg {
        vertical-align: middle;
        font-size: 20px;
        margin-left: 3px;
      }
      &:hover {
        svg {
          color: inherit;
        }
      }
    }
  }

  .add-tokens {
    > p {
      margin: 15px 0px;
      text-align: center;
    }
    button {
      display: block;
      width: 100%;
      text-align: left;
      margin-top: 18px;
      padding-left: 20px;
    }
  }
}
